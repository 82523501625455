import { ACTION_TYPES } from "@store/actionTypes";

// **  Initial State
const initialState = {
  fullDateFilter: null,
  collectingDateUnix: null,
  materialType: null,
  measureUnit: null,
  project: null,
  facility: null,
};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_FILTERS:
      return {
        ...state,
        ...action.data.newFilters,
      };
    default:
      return state;
  }
};

export default filtersReducer;
