import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const lagomLogo = <Box component="img" src="/logo/lagom_logo.svg" sx={{ width: 40, height: 40, ...sx }} />;

  if (disabledLink) {
    return <>{lagomLogo}</>;
  }

  return <RouterLink to="/">{lagomLogo}</RouterLink>;
}
